import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionals } from "../../../actions/workerCategory";
import ImageSection from "./ImageSection";
import ServiceDescription from "./ServiceDescription";
import ShowDynamicProperties from "./ShowDynamicProperties";

// import data from "../../TestData/HomeStay/homestays.json";
// import flat2 from "../../TestData/HomeStay/flat-2.json";
// import flat3 from "../../TestData/HomeStay/flat-3.json";

import AddressInImageCards from "../utils/common/AddressInImageCards";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";

const ShowServiceDetails = ({ match }) => {

  const { handleCallClick, renderLoginModal } = useLoginPhoneUtils();

  const professional = useSelector((state) => state.professional);
  const [profDetails, setProfDetails] = useState(professional.professionals);

  const dispatch = useDispatch();

  useEffect(() => {
    const profId = match.params.profId;
    dispatch(getProfessionals({ profId }));
  }, []);

  useEffect(() => {
    setProfDetails(professional.professionals);
  }, [professional.professionals]);

  // const professional = data;
  // const profDetails = flat2;


  return (
    <div className="flex flex-col">
      <>
        {
          profDetails?.[0]?.images.length > 0 &&
          <ImageSection
            images={profDetails?.[0]?.images}
            profId={profDetails?.[0]?._id}
          />
        }
      </>
      <div className={`${profDetails?.[0]?.images.length === 0 && "lg:mt-4 mt-2"}`}>
        <ServiceDescription
          name={profDetails?.[0]?.firmName}
          description={profDetails?.[0]?.description}
        />
      </div>

      <div className="lg:mx-[20em]">
        <AddressInImageCards
          location={profDetails?.[0]?.goToLocation}
          address={profDetails?.[0]?.address}
        />
      </div>
      {bookNowButton()}
      {
        profDetails?.[0]?.properties && Object.keys(profDetails?.[0]?.properties).length > 0 &&
        <>
          <ShowDynamicProperties
            imageSectionOrNot={(profDetails?.[0]?.images).length}
            properties={profDetails?.[0]?.properties}
            servicePropertyFields={profDetails?.[0]?.servicePropertyFields}
          />
          {bookNowButton()}
        </>

      }

    </div>
  );

  function bookNowButton() {
    return <div className="flex items-center justify-center p-4">
      <button
        className="text-md text-center bg-blue-400 px-6 py-2 shadow rounded-md font-bold cursor-pointer mt-2 hover:bg-blue-500 transition duration-300"
        onClick={() => handleCallClick(profDetails?.[0]?.address.mobile, profDetails?.[0]?._id)}
      >
        Book now
      </button>
    </div>;
  }
};

export default ShowServiceDetails;
