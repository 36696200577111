import React from 'react';
import { Table } from 'antd';

const RegisterProfessional = (props) => {
  const { serviceName, choiceOfService, price, durationInMonths, numberOfLeads, subscriptionType } = props;

  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
    },
    ...(subscriptionType === 'Enrolment Basis'
      ? [
        {
          title: 'Per Month',
          dataIndex: 'perMonth',
          key: 'perMonth',
        },
        {
          title: 'Duration',
          dataIndex: 'duration',
          key: 'duration',
        },
      ]
      : subscriptionType === 'Lead Basis'
        ? [
          {
            title: 'Per Lead',
            dataIndex: 'perLead',
            key: 'perLead',
          },
          {
            title: 'No. of Leads',
            dataIndex: 'noOfLeads',
            key: 'noOfLeads',
          },
        ]
        : subscriptionType === 'Free Trial'
          ? [
            {
              title: 'Subscription Type',
              dataIndex: 'subscriptionType',
              key: 'subscriptionType',
              render: () => 'Free Trial',
            },
          ]
          : []),
    {
      title: 'Registration Fees',
      dataIndex: 'registrationFees',
      key: 'registrationFees',
    },
  ];

  const dataSource = [
    {
      key: '1',
      service: Object.keys(choiceOfService).length === 0 ? serviceName : choiceOfService.name,
      ...(subscriptionType === 'Enrolment Basis' && {
        perMonth: price / durationInMonths,
        duration: `${durationInMonths} months`,
      }),
      ...(subscriptionType === 'Lead Basis' && {
        perLead: price / numberOfLeads,
        noOfLeads: numberOfLeads,
      }),
      ...(subscriptionType === 'Free Trial' && {
        subscriptionType: 'Free Trial',
      }),
      registrationFees: price,
    },
  ];

  const totalRegistrationFees = (
    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '1rem', marginRight: '6em', marginLeft: "1em" }}>
      <span>Total Registration Fees</span>
      <span>{price}</span>
    </div>
  );

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      {totalRegistrationFees}
    </div>
  );
};

export default RegisterProfessional;