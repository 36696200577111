import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { ADMIN, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";
import { generatePublicImgURL } from "../../../urlConfig";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";
import { PROFILE_ACTIONS, REGISTER_ACTION } from "../utils/constants/CommonConstants";
import { BUTTON, HOME_PAGE, NAVIGATION, REGISTER_BUTTON } from "../../../utils/Google Analytics/GA4Constants";
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";

export default function MobileFooterNavItems() {

  const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();

  return (
    <Box
      sx={{
        zIndex: 40,
        pb: 7,
      }}
    >
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          style={{ textDecorationColor: "black" }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'HomeIcon.svg')} alt="HomeIcon" />}
            LinkComponent={Link}
            to="/"
          />

          <BottomNavigationAction
            label="Register"
            icon={<img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/'+ 'RegisterIcon.svg')} alt="RegisterIcon" />}
            onClick={() => {
              TrackGoogleAnalyticsEvent(HOME_PAGE, REGISTER_BUTTON, BUTTON);
              handleAuthentication(NAVIGATION, REGISTER_ACTION)
            }}
            // LinkComponent={Link}
            // to='/add-service'
          />

          <BottomNavigationAction
            label="My Profile"
            icon={<img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'UserIcon.svg')} alt="UserIcon" />}
            onClick={() => handleAuthentication(NAVIGATION, PROFILE_ACTIONS)}
          />
        </BottomNavigation>
      </Paper>
      {
        renderLoginModal()
      }
    </Box>
  );
}