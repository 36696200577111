export const NAVIGATION = "Navigation"
export const BUTTON = "Button"
export const FORM = "form"
export const INPUT_FIELD = "Input Field"
export const LOGIN_MODAL_WINDOW = "Login Modal Window"
export const PROFESSIONAL_ONBOARDING_FORM = "Professional Onboarding Form"
export const HOME_PAGE = "Home Page"

export const CLICKED = "Clicked"
export const SUBMITTED = "Submitted"

export const JOIN_NOW = "JOIN_NOW"
export const REGISTER_BUTTON = "REGISTER_BUTTON"
export const PROFESSIONAL_REGISTRATION_SUBMITTED = "PROFESSIONAL_REGISTRATION_SUBMITTED"
export const PROFESSIONAL_REGISTRATION_FREE = "PROFESSIONAL_REGISTRATION_FREE"
export const SECTOR_SELECTED = "SECTOR_SELECTED"
export const SERVICE_SELECTED = "SERVICE_SELECTED"
export const ADDRESS_ADDED = "ADDRESS_ADDED"
export const ADDRESS_SELECTED = "ADDRESS_SELECTED"
export const SUBSCRIPTION_PLAN_SELECTED = "SUBSCRIPTION_PLAN_SELECTED"
export const GET_LOGIN_OTP_CLICKED = "Get Login OTP"
export const VERIFY_LOGIN_OTP_CLICKED = "Verify Login OTP"
export const LOGIN_OTP_SEND_SUCCESS = "LOGIN_OTP_SEND_SUCCESS"
export const LOGIN_OTP_SEND_FAILED = "LOGIN_OTP_SEND_FAILED"
export const LOGIN_OTP_VERIFY_SUCCESS = "LOGIN_OTP_VERIFY_SUCCESS"
export const LOGIN_OTP_VERIFY_FAILED = "LOGIN_OTP_VERIFY_FAILED"
export const LOGOUT = "Logout"