import React from "react";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, HOME_PAGE_ICON_PHOTOS } from "../utils/constants/s3Constants";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";
import { BUTTON, HOME_PAGE, JOIN_NOW, NAVIGATION } from "../../../utils/Google Analytics/GA4Constants";
import { REGISTER_ACTION } from "../utils/constants/CommonConstants";
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";
const MobileTopBannerCard = (props) => {
  const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();
  const { heading, subHeading, image } = props;

  return (
    <div className="flex w-full items-center lg:mt-4">
      <div className="flex bg-white md:gap-4 lg:px-[2em] rounded-lg shadow-xs overflow-hidden w-full border border-black/15 lg:h-[27em] lg:max-w-[80em] m-auto w-full justify-center items-center">
        <div className="md:flex-1  p-6">
          <div className="lg:mb-[6em]">
            <h2 className="lg:text-[3.5em] text-[1em] font-extrabold leading-[1.2em] lg:mb-2">{heading}</h2>
            <p className="lg:text-[1.2em] text-[0.4em] mb-4 font-normal text-black/70">{subHeading}</p>
          </div>
          <button
            onClick={() => {
              TrackGoogleAnalyticsEvent(HOME_PAGE, JOIN_NOW, BUTTON);
              handleAuthentication(NAVIGATION, REGISTER_ACTION)
            }}
            className="flex items-center justify-center md:font-semibold px-[1em] lg:px-[6em] text-sm md:text-md bg-black text-white py-1 lg:py-2 rounded-full hover:bg-gray-800 transition-colors"
          >
            Join now
          </button>
        </div>
        <div className="flex items-center justify-center w-full md:w-auto p-4">
          <img
            className="max-w-[85%]  object-fit"
            src={generatePublicImgURL(
              ADMIN + "/" + HOME_PAGE_ICON_PHOTOS + "/" + image
            )}
            alt="img"
          />
        </div>
        {renderLoginModal()}
      </div>
    </div>
  );
};

export default MobileTopBannerCard;
