import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36,
        justifyContent: 'flex-start',
        width: '50%'
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
});

const BillTo = ({ job }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>{job.firmName}</Text>
        <Text>Created By: {job.createdBy}</Text>
        <Text>{job.address.pincodeData.poName} {job.address.pincodeData.district}</Text>
        <Text>{job.address.pincodeData.stateName} {job.address.pincodeData.pincode}</Text>
        <Text>Mobile: {job.reachableMobile}</Text>
    </View>
);

export const BillToAddProfessional = ({ prof }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>
            {
                prof?.firmName?.trim()
                    ? prof.firmName
                    : prof.name?.trim()
                        ? prof.name
                        : prof.address?.name?.trim()
                            ? prof.address.name
                            : ""
            }
        </Text>

        <Text>{prof.address.pincodeData.poName} {prof.address.pincodeData.district}</Text>
        <Text>{prof.address.pincodeData.stateName} {prof.address.pincodeData.pincode}</Text>
        <Text>Mobile: {prof.mobile}</Text>
    </View>
);

export default BillTo;