import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "react-multi-carousel/lib/styles.css";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN } from "../utils/constants/s3Constants";
import { useMediaQuery } from 'react-responsive'
import { NextArrow, PrevArrow } from "../layout/CustomArrows";
import { Modal } from "antd";

const GoToServices = ({ goToServiceList }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const [selectedSector, setSelectedSector] = useState(null);

    const settings = {
        // dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: isTabletOrMobile ? 3 : 5,
        slidesToScroll: isTabletOrMobile ? 3 : 5,
        // autoplay: true,
        autoplaySpeed: 3000, // Adjust the autoplay speed in milliseconds
        // arrows: true, // Remove navigation icon,
        swipe: isTabletOrMobile ? true : false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const history = useHistory();

    const handleServiceClick = (service) => {
        // history.push(service.link)
        const stateObj = { serviceName: service.serviceName };
        if (service.choiceName) {
            stateObj.choiceName = service.choiceName;
        }
        if (service.cardType) {
            stateObj.cardType = service.cardType;
        }
        history.push({
            pathname: '/show-service',
            state: stateObj
        });
    };

    return (
        <>
            {
                goToServiceList.map((goToService, index) => (
                    <div key={index} className="lg:max-w-[80em] m-auto w-full px-2 py-1 mt-7">
                        <div className="mb-4 border-b-2 pb-2 border-gray-300">
                            <button
                                onClick={() => setSelectedSector(goToService)}
                                className="group flex items-center gap-3 text-lg md:text-xl font-bold text-black hover:text-blue-600 transition-all"
                            >
                                {goToService.sectorName}

                                <span className="text-sm font-medium text-blue-600 group-hover:underline group-hover:tracking-wide transition-all flex items-center">
                                    View All
                                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H3m0 0l4 4m-4-4l4-4m9 8V8m0 4h7"></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="rounded-md justify-start">
                            <Slider {...settings} >
                                {
                                    goToService.services.map((service, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handleServiceClick(service)}
                                            className={`flex flex-col justify-center items-center cursor-pointer ${service.comingSoon ? 'pointer-events-none' : ""}`}
                                        >
                                            <div className={`lg:w-[15em] lg:h-[15em]  md:w-[150px] md:h-[150px] w-[120px] h-[120px] overflow-hidden rounded-lg border-1 bg-[#f5f5f5] relative`}>
                                                <img
                                                    src={generatePublicImgURL(ADMIN + '/' + goToService.s3PathName + service.image)}
                                                    alt={service.name}
                                                    width="full"
                                                    className="object-cover hover:scale-110 transitions-all duration-200"
                                                />
                                            </div>
                                            {service.comingSoon && (
                                                <p className="text-[0.8em] font-bold text-black bg-[#f5f5f5] p-2 rounded-md mt-1 absolute bottom-7 ">Coming Soon</p>
                                            )}
                                            <p className="text-[0.8em] mt-1 md:mt-2 lg:mt-4 lg:text-[1em] font-bold">{service.name}</p>
                                        </button>
                                    ))
                                }
                                {/* Add additional placeholders if the number of services is less than slidesToShow */}
                                {Array.from({ length: Math.max(0, settings.slidesToShow - goToService.services.length) }).map((_, placeholderIndex) => (
                                    <div key={`placeholder-${placeholderIndex}`}></div>
                                ))}
                                {/* </Carousel> */}
                            </Slider>
                        </div>
                    </div>
                ))
            }

            {/* Modal Window */}
            {selectedSector && (
                <Modal
                    title={selectedSector.sectorName}
                    open="true"
                    onCancel={() => setSelectedSector(null)}
                    footer={null}
                >
                    <div className="flex flex-wrap gap-4 justify-center">
                        {
                            selectedSector.services.map((service, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleServiceClick(service)}
                                    className={`flex flex-col justify-center items-center cursor-pointer ${service.comingSoon ? "pointer-events-none" : ""}`}
                                >
                                    <div className="w-[120px] h-[120px] md:w-[150px] md:h-[150px] lg:w-[15em] lg:h-[15em] overflow-hidden rounded-lg border bg-[#f5f5f5] relative">
                                        <img
                                            src={generatePublicImgURL(ADMIN + '/' + selectedSector.s3PathName + service.image)}
                                            alt={service.name}
                                            className="object-cover hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <p className="text-[0.8em] mt-1 md:mt-2 lg:mt-4 lg:text-[1em] font-bold">{service.name}</p>
                                </button>
                            ))
                        }
                    </div>
                </Modal>
            )}
        </>
    );
};

export default GoToServices;