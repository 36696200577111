import React, { useState, useEffect } from "react";
import { Table, Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { addSubscription, getSubscriptions, toggleSubscription, updateSubscriptionPrice } from "../../actions/subscription";
import { getServices } from "../../actions/service";
import { selectChoice } from "../utils/choiceOfService";

const Subscriptions = () => {

  const dispatch = useDispatch();

  const service = useSelector((state) => state.service);
  const subscription = useSelector((state) => state.subscription);
  const auth = useSelector((state) => state.auth);

  const [subscriptions, setSubscriptions] = useState(subscription.subscriptions);
  const [services, setServices] = useState(service.services);


  const [addSubscriptionModal, setAddSubscriptionModal] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(Object)
  const [choiceOfService, setChoiceOfService] = useState(Object)
  const [choices, setChoices] = useState(null)

  const [serviceId, setServiceId] = useState('');
  const [choiceId, setChoiceId] = useState('');
  const [duration, setDuration] = useState('');
  const [numberOfLeads, setNumberOfLeads] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('')
  const [enabled, setEnabled] = useState(true)

  // Input states to filter out subscriptions using getSubscriptions
  const [serviceDetailsFilter, setServiceDetailsFilter] = useState(Object)
  const [choiceOfServiceFilter, setChoiceOfServiceFilter] = useState(Object)
  const [choicesFilter, setChoicesFilter] = useState(null)

  const [serviceIdFilter, setServiceIdFilter] = useState('');
  const [choiceIdFilter, setChoiceIdFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('')
  const [enabledFilter, setEnabledFilter] = useState(true)

  // Input fields for price change
  const [newPrice, setNewPrice] = useState(null)

  const handleAddSubscriptionEnableToggle = (checked) => {
    setEnabled(checked)
  };

  const handleEnableToggleFilter = (checked) => {
    setEnabledFilter(checked)
  };

  const handleAPIEnableToggle = (checked, subsId) => {
    const subscriptionObj = {
      serviceId: serviceIdFilter,
      choiceId: choiceIdFilter,
      type: typeFilter,
      enabled: enabledFilter
    }
    dispatch(toggleSubscription(subscriptionObj, subsId))
  }

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: subscriptions.length, // Set the total count initially
    },
  });

  useEffect(() => {
    setSubscriptions(subscription.subscriptions);
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...prevParams.pagination,
        total: subscription.subscriptions.length, // Update the total count
      },
    }));
  }, [subscription.subscriptions]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getSubscriptions());
      dispatch(getServices());
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Check if at least one of the filter values is not empty/null/undefined
      if (serviceIdFilter || choiceIdFilter || typeFilter) {
        const subscriptionObj = {
          serviceId: serviceIdFilter,
          choiceId: choiceIdFilter,
          type: typeFilter,
          enabled: enabledFilter
        }
        dispatch(getSubscriptions(subscriptionObj));
      }
    }
  }, [serviceIdFilter, choiceIdFilter, typeFilter, enabledFilter]);

  useEffect(() => {
    setServices(service.services);
  }, [service.services]);

  useEffect(() => {
    if (serviceDetails != null) {
      setServiceId(Object.keys(serviceDetails).length === 0 ? '' : serviceDetails._id);
      setChoices(serviceDetails ? serviceDetails.choices : null);
      setChoiceId('')
    }
  }, [serviceDetails?._id]);

  useEffect(() => {
    if (Object.keys(choiceOfService).length > 0) {
      setChoiceId(choiceOfService._id);
    }
  }, [choiceOfService?._id]);

  useEffect(() => {
    if (serviceDetailsFilter != null) {
      setServiceIdFilter(Object.keys(serviceDetailsFilter).length === 0 ? '' : serviceDetailsFilter._id);
      setChoicesFilter(serviceDetailsFilter ? serviceDetailsFilter.choices : null);
      setChoiceIdFilter('')
    }
  }, [serviceDetailsFilter?._id]);

  useEffect(() => {
    if (Object.keys(choiceOfServiceFilter).length > 0) {
      setChoiceIdFilter(choiceOfServiceFilter._id);
    }
  }, [choiceOfServiceFilter?._id]);

  const showAddSubscriptionModal = () => {
    setAddSubscriptionModal(true);
  };

  const handleCloseAddSubscriptionModal = () => {
    setAddSubscriptionModal(false);
    resetInputFields(setServiceId, setChoiceId, setDuration, setPrice, setType, setEnabled);
  };

  const addSubscriptionForm = () => {
    const newSubscription = {
      serviceId,
      choiceId,
      durationInMonths: duration,
      numberOfLeads,
      price,
      type,
      enabled
    }

    // Filter object
    const subscriptionObj = {
      serviceId: serviceIdFilter,
      choiceId: choiceIdFilter,
      type: typeFilter,
      enabled: enabledFilter
    }
    dispatch(addSubscription(newSubscription, subscriptionObj));
    setAddSubscriptionModal(false);
    resetInputFields(setServiceId, setChoiceId, setDuration, setPrice, setType, setEnabled);
  };

  const handlePriceChange = (subsId) => {
    const subscriptionObj = {
      serviceId: serviceIdFilter,
      choiceId: choiceIdFilter,
      type: typeFilter,
      enabled: enabledFilter
    }
    dispatch(updateSubscriptionPrice(subscriptionObj, subsId, newPrice));
  }

  const renderAddSubscriptionModal = () => {
    return (
      <Modal
        title="Add new Subscription"
        open={addSubscriptionModal}
        onOk={addSubscriptionForm}
        onCancel={handleCloseAddSubscriptionModal}
      >
        <Autocomplete
          sx={{
            width: 'full',
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              paddingBlock: '6px',
              textAlign: 'center',
              backgroundColor: "#E0E0E0",
              color: 'black',
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "black",
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: "#e0e0e0",
            },
            "& .MuiAutocomplete-option": {
              "&:hover": {
                backgroundColor: "#d0d0d0",
              },
            },
          }}
          options={services}
          getOptionLabel={(option) => option?.type}
          value={services.find((option) => option._id === serviceDetails?._id) || null}
          onChange={(event, newValue) => setServiceDetails(newValue || {})}
          renderInput={(params) => (
            <TextField {...params} label="Select Service" variant="outlined" />
          )}
        />

        {
          selectChoice(choices, choiceOfService, setChoiceOfService, serviceDetails)
        }

        <select
          className="form-control py-[1.02em] w-full my-2 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value=''>Select subscription type</option>
          <option value='Enrolment Basis'>Enrolment Basis</option>
          <option value='Lead Basis'>Lead Basis</option>
          {/* <option value='Monthly Basis'>Monthly Basis</option> */}
          <option value='Free Trial'>Free Trial</option>
        </select>

        {type === "Enrolment Basis" && (
          <input
            className="form-control py-[1.02em] w-full border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={duration}
            placeholder="Duration"
            onChange={(e) => setDuration(e.target.value)}
          />
        )}

        {type === "Lead Basis" && (
          <input
            className="form-control py-[1.02em] w-full border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={numberOfLeads}
            placeholder="Number of Leads"
            onChange={(e) => setNumberOfLeads(e.target.value)}
          />
        )}

        <input
          className="form-control w-full py-[1.02em] border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          label="Price"
          value={price}
          placeholder={`Price`}
          onChange={(e) => setPrice(e.target.value)}
        />

        <Switch checkedChildren="Disable Subscription" unCheckedChildren="Enable Subscription" defaultChecked onChange={handleAddSubscriptionEnableToggle} />


      </Modal>
    );
  }

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: true,
      render: (text, record, index) =>
        (tableParams.pagination.current - 1) * tableParams.pagination.pageSize +
        index +
        1,
      width: "20%",
    },
    {
      title: "ServiceName",
      dataIndex: "serviceName",
      width: "20%",
    },
    {
      title: "Choice",
      dataIndex: "choiceName",
      width: "20%",
    },
    {
      title: "Type Of Enrolment",
      dataIndex: "type",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price, subscription) => (
        <>
          <p>{price}</p>
          <input
            type="number"
            onChange={(e) => setNewPrice(Number(e.target.value))}
            className="border border-black/60"
          />
          <button className="bg-black/50 p-1 px-3 text-white" onClick={() => handlePriceChange(subscription._id)}>Update Price</button>
        </>
      )
    },
    {
      title: "Duration",
      dataIndex: "durationInMonths"
    },
    {
      title: "NoOfLeads",
      dataIndex: "numberOfLeads"
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      render: (enabled, subscription) => (
        <>
          <p>{enabled.toString()}</p>
          <Switch
            checked={enabled}
            onChange={(checked) => handleAPIEnableToggle(checked, subscription._id)}
            checkedChildren="Disable"
            unCheckedChildren="Enable"
          />
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div className="flex flex-col justify-center gap-3 my-4 lg:mx-[20em] ">
      <div className=" w-full flex flex-col gap-3mx-2 justify-center m-auto">
        <div className="flex flex-col md:flex-row m-auto gap-3">
          <Autocomplete
            sx={{
              width: 300,
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                paddingBlock: '6px',
                textAlign: 'center',
                backgroundColor: "#E0E0E0",
                color: 'black',
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "black",
              },
              "& .MuiAutocomplete-listbox": {
                backgroundColor: "#e0e0e0",
              },
              "& .MuiAutocomplete-option": {
                "&:hover": {
                  backgroundColor: "#d0d0d0",
                },
              },
            }}
            options={services}
            getOptionLabel={(option) => option?.type}
            value={services.find((option) => option._id === serviceDetailsFilter?._id) || null}
            onChange={(event, newValue) => setServiceDetailsFilter(newValue || {})}
            renderInput={(params) => (
              <TextField {...params} label="Select Service" variant="outlined" />
            )}
          />

          {
            selectChoice(choicesFilter, choiceOfServiceFilter, setChoiceOfServiceFilter, serviceDetailsFilter)
          }

          <select
            className="form-control py-3.5 text-[1.05em] border-black/40 text-black/55 bg-black/15 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <option value=''>Select subscription type</option>
            <option value='Enrolment Basis'>Enrolment Basis</option>
            <option value='Lead Basis'>Lead Basis</option>
            {/* <option value='Monthly Basis'>Monthly Basis</option> */}
            <option value='Free Trial'>Free Trial</option>
          </select>
          <div className="flex flex-col gap-3">
            <Switch className="mx-[3em] mt-1" checkedChildren="Disable Subscription" unCheckedChildren="Enable Subscription" defaultChecked onChange={handleEnableToggleFilter} />
            <button onClick={showAddSubscriptionModal} className="blue-gradient px-3 py-2 rounded-full uppercase text-white font-extrabold">
              Add subscription +
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-scroll">
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={subscriptions.map((subscription, index) => ({
            key: index,
            serviceName: subscription.serviceId.type,
            choiceName: subscription.choiceOfService ? subscription.choiceOfService.name : '-',
            type: subscription?.type,
            price: subscription.price,
            durationInMonths: subscription.durationInMonths,
            numberOfLeads: subscription.numberOfLeads,
            enabled: subscription.enabled,
            _id: subscription._id
          }))}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </div>
      {renderAddSubscriptionModal()}
    </div>
  );
};

export default Subscriptions
function resetInputFields(setServiceId, setChoiceId, setDuration, setPrice, setType, setEnabled) {
  setServiceId('');
  setChoiceId('');
  setDuration('');
  setPrice('');
  setType('');
  setEnabled(true);
}

