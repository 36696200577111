import { ProfessionalStatConstants } from './types';
import backendAxios from '../utils/backendAxios';

//Admin
export const incrementServiceProfileViews = (profId) => async (dispatch) => {
    try {
        dispatch({ type: ProfessionalStatConstants.INCREMENT_SERVICE_PROFILE_VIEWS_REQUEST });
        const res = await backendAxios.put(`/api/workerCategory/stats/increment-profile-visit/${profId}`);
        if (res.status === 200) {
            dispatch({ type: ProfessionalStatConstants.INCREMENT_SERVICE_PROFILE_VIEWS_SUCCESS });
        } else {
            const { error } = res.data;
            dispatch({
                type: ProfessionalStatConstants.INCREMENT_CALL_COUNT_FAILURE,
                payload: { error }
            });
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: ProfessionalStatConstants.INCREMENT_SERVICE_PROFILE_VIEWS_FAILURE,
            payload: { error }
        });
    }
};

export const incrementCallCount = (profId) => async (dispatch) => {
    try {
        dispatch({ type: ProfessionalStatConstants.INCREMENT_CALL_COUNT_REQUEST });
        const res = await backendAxios.put(`/api/workerCategory/stats/increment-call/${profId}`);
        if (res.status === 200) {
            dispatch({ type: ProfessionalStatConstants.INCREMENT_CALL_COUNT_SUCCESS });
        } else {
            const { error } = res.data;
            dispatch({
                type: ProfessionalStatConstants.INCREMENT_CALL_COUNT_FAILURE,
                payload: { error }
            });
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: ProfessionalStatConstants.INCREMENT_CALL_COUNT_FAILURE,
            payload: { error }
        });
    }
};