import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import ImageCard from './ImageCard'
import NoProfessionalsFound from './NoProfessionalsFound'
import ProfileCard from '../../profile/ProfileCard';
import { useDispatch } from 'react-redux';
import { incrementServiceProfileViews } from '../../../../actions/professionalStats';

const ServiceLayout = ({ professionals, cardType }) => {
    const CardComponent = cardType === 'profile' ? ProfileCard : ImageCard;
    const dispatch = useDispatch();
    const history = useHistory();

    const handleServiceProfileViewClick = (profId) => {
        dispatch(incrementServiceProfileViews(profId));
        history.push(`show-service-details/${profId}`); // Navigate manually
    }

    return (
        <div className="mt-3 lg:grid lg:grid-cols-3 gap-3 md:grid md:grid-cols-2">
            {
                professionals?.length > 0 ? (
                    professionals.map((professional) => (
                        <>
                            {
                                cardType === 'profile' ? (
                                    (professional?.images?.length > 0 ||
                                        (professional.properties && Object.keys(professional.properties).length > 0)
                                    ) ? (
                                        <CardComponent
                                            key={professional._id}
                                            professional={professional}
                                            onCardClick={() => handleServiceProfileViewClick(professional._id)}
                                        />
                                    ) : (
                                        <CardComponent key={professional._id} professional={professional} />
                                    )
                                ) : (
                                    (professional?.images?.length > 0 ||
                                        (professional.properties && Object.keys(professional.properties).length > 0)
                                    ) ? (
                                        <CardComponent
                                            key={professional._id}
                                            professional={professional}
                                            onCardClick={() => handleServiceProfileViewClick(professional._id)}
                                        />
                                    ) : (
                                        <CardComponent key={professional._id} professional={professional} />
                                    )
                                )
                            }
                        </>
                    ))
                ) : (
                    <NoProfessionalsFound />
                )
            }
        </div>
    )
}

export default ServiceLayout