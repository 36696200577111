import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { GrPowerShutdown } from "react-icons/gr";
import { MdLogin } from "react-icons/md";
import { useMediaQuery } from 'react-responsive'
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";
import { BUTTON, CLICKED, HOME_PAGE, LOGOUT, NAVIGATION, REGISTER_BUTTON } from "../../../utils/Google Analytics/GA4Constants";
import { logout } from "../../../actions/auth";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";
import { HOME, MY_SERVICES, REGISTER_ACTION } from "../utils/constants/CommonConstants";

const NavItems = () => {

    const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();

    /* Redux states */
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [showLogout, setShowLogout] = useState(false);
    const logoutRef = useRef(null);

    const handleOperations = () => {
        setShowLogout(!showLogout);
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

    const signout = () => {
        TrackGoogleAnalyticsEvent(CLICKED, LOGOUT, BUTTON)
        dispatch(logout());
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (logoutRef.current && !logoutRef.current.contains(event.target)) {
                setShowLogout(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="bg-white border-b shadow-sm border-b-black/10  flex justify-center lg:px-3 items-center py-3.5 z-40 sticky top-0">
                <div className="flex justify-between items-center w-full mx-3 lg:w-[80em]">
                    <div className="flex items-center justify-center gap-1">
                        {/* <img src={generatePublicImgURL(ADMIN + '/' + 'BharatProsLogo.png')} alt="bharat_pros" width={22} /> */}
                        <Link to='/' className="no-underline">
                            <div className="mt-2">
                                <p className="text-black text-2xl font-bold  ">BharatPros</p>
                            </div>
                        </Link>
                    </div>
                    <div className="flex items-center gap-8">
                        {
                            isDesktopOrLaptop && <p className="hover:text-blue-400 font-bold"
                                onClick={() => {
                                    TrackGoogleAnalyticsEvent(HOME_PAGE, REGISTER_BUTTON, BUTTON);
                                    handleAuthentication(NAVIGATION, REGISTER_ACTION)
                                }}
                            >
                                Register as Service Provider
                            </p>
                        }
                        {
                            isDesktopOrLaptop && <p className="hover:text-blue-400 font-bold" onClick={() => handleAuthentication(NAVIGATION, MY_SERVICES)}>My Services</p>
                        }

                        {
                            authState?.isAuthenticated ? (
                                <div>
                                    <button
                                        className=" relative flex items-center justify-center gap-1 px-3 py-2 rounded-[5px] shadow-sm border border-black/15  text-black transition-all duration-200 font-bold"
                                        onClick={handleOperations}
                                    >
                                        <p>{authState?.user?.name}</p>
                                    </button>
                                    {
                                        showLogout && (
                                            <div ref={logoutRef} className="absolute border border-black/10  mt-1 rounded-md bg-white w-[200px] h-[100px] right-3">
                                                <p className="text-xl p-3 font-bold" >{authState?.user?.name}</p>
                                                <hr />
                                                <div className="flex   items-center p-3 gap-1  text-lg " onClick={signout}>
                                                    <button className="">Logout</button>
                                                    <GrPowerShutdown />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ) : (
                                <button
                                    className="flex items-center justify-center gap-1 px-3 py-2 rounded-[5px] shadow-md border border-black/30 text-black transition-all duration-200 "
                                    onClick={() => handleAuthentication(NAVIGATION, HOME)}
                                >
                                    Login
                                    <MdLogin />
                                </button>
                            )
                        }
                    </div>
                </div >
            </div>

            {
                renderLoginModal()
            }
        </>
    );
};

export default NavItems;