import React, { useState } from "react";
import { Modal } from "antd";
import { MdLogin } from "react-icons/md";
import { register } from '../../../actions/auth';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { getAuth, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider } from "firebase/auth";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { log } from "winston";
import Spinner from "../../layout/Spinner";
import DotSpinner from "../../layout/DotSpinner.gif"
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";
import { BUTTON, GET_LOGIN_OTP_CLICKED, LOGIN_MODAL_WINDOW, LOGIN_OTP_SEND_FAILED, LOGIN_OTP_SEND_SUCCESS, LOGIN_OTP_VERIFY_FAILED, LOGIN_OTP_VERIFY_SUCCESS, VERIFY_LOGIN_OTP_CLICKED } from "../../../utils/Google Analytics/GA4Constants";

const LoginModal = (props) => {

    const [mobile, setMobile] = useState('')
    const [otp, setOtp] = useState('')
    const [formatMobile, setFormatMobile] = useState('')

    const [showLoader, setShowLoader] = useState(false)

    const dispatch = useDispatch();

    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyDCFoMgvtkqxQNnhFI-iP88XdYimFfUlcU",
        authDomain: "fir-auth-a524c.firebaseapp.com",
        projectId: "fir-auth-a524c",
        storageBucket: "fir-auth-a524c.appspot.com",
        messagingSenderId: "587065127515",
        appId: "1:587065127515:web:ead63e2bc7d24073c4457e",
        measurementId: "G-YLNPGPEN0W"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const onSubmit = async e => {
        e.preventDefault();
        setShowLoader(!showLoader)
        const formatPh = "+" + mobile;
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("captcha resolved");
            }
        }, auth);
        const appVerifier = window.recaptchaVerifier;
        setFormatMobile(formatPh)
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, LOGIN_OTP_SEND_SUCCESS, BUTTON)
            })
            .catch((error) => {
                // Error; SMS not sent
                // ...
                TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, LOGIN_OTP_SEND_FAILED, BUTTON)
                alert(error.message);
            });
        TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, GET_LOGIN_OTP_CLICKED, BUTTON)
    };

    const otpSubmit = async e => {
        e.preventDefault();
        setShowLoader(!showLoader)
        setMobile('')
        window.confirmationResult
            .confirm(otp)
            .then((confirmationResult) => {
                const newUser = {
                    mobile: formatMobile
                }
                dispatch(register(newUser));
                TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, LOGIN_OTP_VERIFY_SUCCESS, BUTTON)
            })
            .catch((error) => {
                // User couldn't sign in (bad verification code?)
                console.log("otp error")
                alert(error.message);
                TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, LOGIN_OTP_VERIFY_FAILED, BUTTON)
            });
        props.handleOk();
        setOtp('')
        TrackGoogleAnalyticsEvent(LOGIN_MODAL_WINDOW, VERIFY_LOGIN_OTP_CLICKED, BUTTON)
    };

    return (
        <Modal
            title="Register / Login"
            open={props.loginModal}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
            footer={null}
        >
            {
                showLoader &&
                <>
                    <img
                        src={DotSpinner}
                        style={{ width: '200px', margin: 'auto', display: 'block' }}
                        alt="Loading..."
                    />
                    <p className="m-auto lg:mx-[4em] md:mx-[3em] my-2 animate-bounce text-green-500 ">OTP sent successfully, you will receive it shortly</p>
                </>
            }
            <div className={`flex flex-col  ${!showLoader ? 'gap-2' : 'gap-3'}`}>
                <div className="grid  ">
                    <div className="grid grid-cols-10 gap-2 lg:mx-[4em] md:mx-[3em]">
                        <div className={`col-span-10 ${!showLoader ? 'mt-4' : ''}`}>
                            <PhoneInput
                                buttonStyle={{ border: "1px solid  #E8E8E8" }}
                                inputStyle={{
                                    padding: "20px",
                                    paddingInline: "50px",
                                    border: "1px solid #E8E8E8",
                                    width: "calc(100% + 0.2px)",
                                    outline: "black",
                                }}
                                country={"in"}
                                value={mobile}
                                onChange={setMobile}
                                disabled={showLoader ? true : false}
                            />
                        </div>

                    </div>
                </div>
                <div className="grid grid-cols-10 gap-2">
                    {
                        showLoader &&
                        (
                            <input
                                type="number"
                                placeholder="Enter OTP"
                                value={otp}
                                autoComplete="false"
                                maxLength="6"
                                onChange={(e) => setOtp(e.target.value)}
                                className="col-span-10 outline-blue-600 text-[15px] border border-[#E8E8E8] rounded-[4px] px-[9px] text-md py-[7px] lg:mx-[3.8em] md:mx-[2.8em] "
                            />
                        )
                    }

                </div>

                <div className="flex justify-end">
                    {!showLoader ?
                        (
                            <button
                                className=" col-span-3 font-extrabold blue-gradient  text-white uppercase rounded-full px-[2em] py-[0.5em]  flex items-center justify-center gap-1 text-black hover:text-white hover:bg-black transition-all duration-200 lg:mr-[4em] md:mr-[3em]"
                                id="sign-in-button"
                                onClick={onSubmit}
                            >
                                LogIn
                                <span>
                                    <MdLogin />
                                </span>
                            </button>
                        ) : (

                            <button
                                className=" col-span-3 font-extrabold blue-gradient text-white uppercase rounded-full  px-[2em] py-[0.5em]  flex items-center justify-center gap-1 text-black hover:text-white hover:bg-black transition-all duration-200 py-[7px] lg:mr-[4em] md:mr-[3em]"
                                onClick={otpSubmit}
                            >
                                Verify
                            </button>
                        )

                    }
                </div>
            </div>
        </Modal>
    )
}

export default LoginModal