import { GET_PROFILE_REQUEST, GET_PROFILE, GET_PROFILES, PROFILE_ERROR, CLEAR_PROFILE, UPDATE_PROFILE, GET_REPOS, ProfileConstants } from "../actions/types";

const initialState = {
    profile: null,
    profiles: [],
    repos: [],
    loading: false,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false
            };
        case GET_PROFILES:
            return {
                ...state,
                profiles: payload,
                loading: false
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false
            };
        case PROFILE_ERROR:
            return {
                ...state,
                profile: null,
                error: payload,
                loading: false
            };
        case CLEAR_PROFILE:
            return {
                ...state,
                profile: null,
                repos: [],
                loading: false
            };
        case GET_REPOS:
            return {
                ...state,
                repos: payload,
                loading: false
            };
        case ProfileConstants.INCREMENT_PROFILE_VISIT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ProfileConstants.INCREMENT_PROFILE_VISIT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ProfileConstants.INCREMENT_PROFILE_VISIT_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}