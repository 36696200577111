import React from 'react';

const SubscriptionPlanCard = ({ subscriptionPlan, selectSubscriptionHandler }) => {
  const { type, price, durationInMonths, numberOfLeads, serviceId } = subscriptionPlan
  const isHighlighted = true

  return (
    <div className={`w-full ${type == "Free Trial" ? 'h-[18em]' : 'h-[20.4em]'} flex-1 my-4 p-5 order-2 shadow-xl rounded-3xl ${isHighlighted ? 'bg-black/5 text-black border border-black/30 lg:order-2 lg:mt-0' : 'bg-white lg:order-1 lg:rounded-r-none'}`}>
      <div className={`mb-3 pb-3 flex items-center border-b ${isHighlighted ? 'border-gray-600' : 'border-gray-300'}`}>
        <div className="ml-5">
          <span className={`block ${isHighlighted ? 'text-md text-black' : 'text-md'} font-semibold mb-3`}>{serviceId?.type}</span>
          <span>
            <span className={`font-medium text-md align-top ${isHighlighted ? '' : 'text-gray-500'}`}>Rs&thinsp;</span>
            <span className={`text-lg font-bold ${isHighlighted ? 'text-black' : ''}`}>
              {
                type === "Enrolment Basis" ? price / durationInMonths
                  : type === "Lead Basis"? price / numberOfLeads
                    : price
              }

            </span>
          </span>
          {
            type === "Enrolment Basis" ? (
              <span className={`font-medium ${isHighlighted ? '' : 'text-gray-500'}`}>/ month</span>
            ) : type === "Lead Basis" ? (
              <span className={`font-medium ${isHighlighted ? '' : 'text-gray-500'}`}>/ lead</span>
            ) : ''
          }

        </div>
      </div>
      <ul className={`mb-2 font-medium ${isHighlighted ? 'text-md' : 'text-gray-500'}`}>
        <div className='md:grid grid-cols-2'>
          <li className={`flex ${isHighlighted ? 'mb-3' : 'text-md  mb-2'}`}>
            <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" alt="check icon" className='filter invert' />
            <span className="ml-3 text-sm">Plan Type: {type === "Enrolment Basis" ? "Subscription-Based" : type}</span>
          </li>
          {type === "Free Trial" ? null : (
            <>
              <li className={`flex ${isHighlighted ? 'mb-3' : 'text-sm mb-2'}`}>
                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" alt="check icon" className='filter invert' />
                <span className="ml-3 text-sm">
                  {type === "Lead Basis" ? `Number of Leads: ${numberOfLeads}` : `Duration: ${durationInMonths} months`}
                </span>
              </li>
            </>
          )}

        </div>
        <li className={`flex ${isHighlighted ? 'mb-3' : 'text-sm  mb-1'}`}>
          <span className="ml-3 flex  text-gray-500 flex-end font-bold">Total Rs
            <span className='ml-3 text-black text-lg font-extrabold'>{price}</span>
          </span>
        </li>
      </ul>
      <button className="flex justify-center items-center blue-gradient font-bold rounded-xl py-3 px-2 text-center text-white text-md w-full"
        onClick={() => selectSubscriptionHandler(subscriptionPlan)}
      >
        {subscriptionPlan ? " Choose Plan" : "Choosen"}
        <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" className="ml-2" alt="arrow icon" />
      </button>
    </div>
  );
};

export default SubscriptionPlanCard;


